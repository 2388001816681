<template>
  <div>
    <Loading v-if="loadingFull" />
    <div class="flex flex-col font-Inter px-10">
      <div class="lg:flex lg:items-center lg:justify-between mb-3">
        <div class="flex-1 min-w-0 flex justify-between py-4">
          <div
            class="mt-1 flex flex-row justify-center items-center sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
          >
            <h2
              class="text-xl uppercase font-medium leading-7 text-gray-900 sm:text-3xl sm:truncate"
            >
              Modification Note de crédit N° {{ invoice.document_no }}
            </h2>
          </div>
        </div>
      </div>
      <div class="mb-20">
        <div class="flex flex-col mx-auto bg-white p-5 shadow">
          <el-form
            :model="invoice"
            :rules="rules"
            ref="invoiceForm"
            :label-position="labelPosition"
          >
            <div class="flex justify-between items-start">
              <div class="flex flex-col w-1/2">
                <search-customer
                  @customer="handleSelectedCustomer"
                  @search-customer="handleSearchCustomer"
                  :customerInfo="customerInfo"
                  :customers="customers"
                />
              </div>
              <div class="flex flex-col justify-end items-end w-1/2">
                <div
                  class="text-3xl font-medium leading-7 text-gray-900 sm:text-3xl sm:truncatemt-2 my-2"
                >
                  Montant TTC: {{ totalTTC | moneyFilter }} {{user.currency_code}}
                </div>
                <el-form-item label="Date" prop="date">
                  <el-date-picker
                    v-model="invoice.date"
                    type="date"
                    placeholder="jj-mm-aaaa"
                    format="dd MMM.yyyy"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </el-form-item>
                <div class="flex">
                  <el-form-item label="Condifition de paiement">
                    <el-select
                      v-model="invoice.terms"
                      @change="handleDueDay"
                      class="mr-3"
                    >
                      <el-option
                        v-for="item in duedays"
                        :key="item.id"
                        :label="item.terms"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="Date d'échéance">
                    <el-date-picker
                      v-model="invoice.due_date"
                      type="date"
                      format="dd MMM.yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>

                <div class="flex">
                  <el-form-item label="Date de livraison">
                    <el-date-picker
                      v-model="invoice.shipping_date"
                      type="date"
                      format="dd MMM.yyyy"
                      value-format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-form>

          <div class="customer-white mt-6">
            <add-article
              :articles="articles"
              :items="items"
              :subTotal="totalHT"
              :totalTTC="totalTTC"
              :sale="true"
              @discount="updateDiscount"
              @percentDiscount="updatePercentDiscount"
              @taxe="updateTaxe"
              @shipping="updateShipping"
            />
          </div>
        </div>
      </div>
      <el-dialog
        :title="`Confirmer la note de crédit N°: ${invoice.document_no}`"
        width="35%"
        top="9vh"
        :close-on-click-modal="false"
        :show-close="false"
        :close-on-press-escape="false"
        :visible.sync="confirmDialog"
      >
        <div class="resume-dialog">
          <div class="item" style="font-weight: bold">
            <span>MONTANT FACTURE TTC :</span>
            <span>{{ totalTTC | moneyFilter }} {{user.currency_code}}</span>
          </div>
          <div class="mt-5">Montant avancé sur la facture</div>
          <div
            v-for="(item, index) in payments"
            :key="index"
            class="item"
            style="font-weight: bold"
          >
            <i
              v-if="payments.length > 1"
              @click="handleDeletePaymentItem(index)"
              style="width: 5%"
              class="el-icon-circle-close text-gray-500 text-2xl cursor-pointer"
            ></i>

            <el-select
              style="width: 70%"
              v-model="item.account_number"
              placeholder="Choisir le compte"
              class="mr-2"
              filterable
              @change="handleSelectAccount(item)"
            >
              <el-option
                v-for="item in accounts"
                :key="item.account_number"
                :label="`${item.account_name}`"
                :value="item.numero"
              >
              </el-option>
            </el-select>
            <span style="width: 25%">
              <el-input-number :controls="false" :min="0" v-model="item.amount">
              </el-input-number
            ></span>
          </div>
          <el-button
            v-if="payments.length < 3"
            type="text"
            class="mt-1"
            @click="handleAccount"
            >Ajouter une ligne</el-button
          >
        </div>

        <div slot="footer" class="border-t pt-2 dialog-footer">
          <el-button @click="confirmDialog = false">Annuler</el-button>
          <el-button type="primary" @click="createData('invoiceForm')"
            >Confirmer</el-button
          >
        </div>
      </el-dialog>
    </div>
    <page-footer>
      <div class="flex justify-between px-10 py-2">
        <div>
          <div
            class="text-2xl font-medium leading-7 text-gray-900 sm:text-2xl sm:truncatemt-2 my-2"
          >
            TOTAL: {{ totalTTC | moneyFilter }} {{user.currency_code}}
          </div>
        </div>
        <div>
          <el-button
            type="info"
            icon="el-icon-save"
            @click="handleCreate('DRAFT')"
            plain
            :disabled="!itemsNoEmpty"
            >Enregistrer au brouillon</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-save"
            @click="handleCreate('OPEND')"
            :disabled="!itemsNoEmpty"
            >Enregistrer et quitter</el-button
          >
        </div>
        <!--   <el-dropdown
          :disabled="!itemsNoEmpty"
          split-button
          type="primary"
          @command="handleCreate"
        >
          Finaliser
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="OPEND" class="text-md font-medium"
              >Enregistrer et confirmer
            </el-dropdown-item>
            <el-dropdown-item command="DRAFT" class="text-md font-medium"
              >Enregistrer au brouillon</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown> -->
      </div>
    </page-footer>
  </div>
</template>

<script>
import { getDocument, updateDocument } from "@/api/sales2";
import { fetchDueDays } from "@/api/settings";
import { parseTime, formatDate } from "@/utils";
import { toThousandFilter } from "@/Filters";
import AddArticle from "@/components/AddArticle";
import SearchCustomer from "@/components/AddCustomer";
import PageFooter from "@/components/PageFooter";
import { fetchAccounts } from "@/api/account";
import Loading from "@/components/Loanding/Full";
import {mapGetters} from "vuex";

export default {
  name: "NOTECREDIT-UPDATE",
  filters: {
    filterTime: parseTime,
    moneyFilter: toThousandFilter,
  },
  components: { AddArticle, SearchCustomer, PageFooter, Loading },
  data() {
    return {
      typeDoc: "CREDIT_NOTE",
      labelPosition: "top",
      loadingFull: false,
      articles: [],
      confirmDialog: false,
      customers: [],
      customerInfo: {
        contact_id: "",
        phone: "",
        email: "",
        first_name: "",
        last_name: "",
        street: "",
        contact_no: "",
        contact_name: "",
        account_credit_balance: 0,
        account_credit_number: "",
        account_credit_title: "",
      },
      accounts: [],
      duedays: [],
      loadingButton: false,
      articleName: "",
      customerName: "",
      selectedArticle: "",
      dialogVisibleValid: false,
      customerDefault: null,
      terms: [],
      loadingArticle: false,
      loadingCustomer: false,
      items: [
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          total: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
        {
          article_id: "",
          article_no: "",
          article_name: "",
          price_purchase: 0,
          price_sale: 0,
          description: "",
          price: "",
          percent_discount: true,
          discount: 0,
          percent_tax: 0,
          quantity: 0,
          total: 0,
          warehouse_code: "",
          warehouse_name: "",
          stock_onhand: "",
          stocks: [],
        },
      ],
      contactId: "",
      warehouses: [],
      payments: [
        {
          account_number: "",
          account_name: "",
          amount: 0,
        },
      ],
      invoice: {
        contact_id: "",
        due_date: "",
        shipping_date: "",
        percent_tax: 0,
        percent_discount: true,
        discount: 0,
        date: formatDate(new Date()),
        terms: "",
        document_no: "",
        shipping_cost: 0,
        shipping_to: "",
        billing_to: "",
        status: "OPEND",
        currency: "",
        line_items: [],
        payments: [
          {
            account_number: "",
            account_name: "",
            amount: 0,
          },
        ],
        note: "",
      },
      rules: {
        contact_id: [
          {
            required: true,
            message: "Veuillez selectionner un client",
            trigger: "blur",
          },
        ],

        date: [
          {
            required: true,
            message: "La date est obligatoire",
          },
        ],
      },
    };
  },

  computed: {
      ...mapGetters(["user"]),
    totalHT: function () {
      var subtotal = 0;

      this.items.forEach((el) => {
        let totalHT = el.quantity * el.price;
        let discount = 0;
        let tax;
        if (el.percent_discount) {
          discount = (totalHT * el.discount) / 100;
        } else {
          discount = el.discount;
        }
        tax = ((totalHT - discount) * el.percent_tax) / 100;
        subtotal += totalHT - discount + tax;
      });

      return Math.floor(subtotal);
    },
    totalTTC: function () {
      var total = 0;
      if (this.invoice.percent_discount) {
        total =
          this.totalHT +
          this.invoice.shipping_cost -
          (this.totalHT * this.invoice.discount) / 100 +
          (this.totalHT * this.invoice.percent_tax) / 100;
      } else {
        total =
          this.totalHT +
          this.invoice.shipping_cost -
          this.invoice.discount +
          (this.totalHT * this.invoice.percent_tax) / 100;
      }
      return Math.floor(total);
    },
    itemsNoEmpty: function () {
      return this.items.some((item) => item.article_id !== "");
    },
  },
  mounted() {
    this.fetchData();
    this.fetchDueDays();
    //this.initData();
    this.getAccounts();
  },
  methods: {
    async fetchData() {
      const id = this.$route.params && this.$route.params.id;
      this.loadingFull = true;
      await getDocument(id, this.typeDoc)
        .then((res) => {
          setTimeout(() => {
            let invoice = res.data;
            this.customerInfo.contact_id = invoice.contact_id;
            this.customerInfo.phone = invoice.phone;
            this.customerInfo.email = invoice.email;
            this.customerInfo.first_name = invoice.first_name;
            this.customerInfo.last_name = invoice.last_name;
            this.customerInfo.street = invoice.contact_street;
            this.customerInfo.contact_no = invoice.contact_no;
            this.customerInfo.contact_name = invoice.contact_name;
            this.customerInfo.account_credit_balance =
              invoice.account_credit_balance;
            this.customerInfo.account_credit_number =
              invoice.account_credit_number;
            this.customerInfo.account_credit_title =
              invoice.account_credit_title;

            this.invoice.contact_id = invoice.contact_id;
            this.invoice.due_date = invoice.due_date_value;
            this.invoice.percent_tax = invoice.percent_tax;
            this.invoice.percent_discount = invoice.percent_discount;
            this.invoice.discount = invoice.discount;
            this.invoice.date = invoice.date_value;
            this.invoice.document_no = invoice.number;
            this.invoice.currency =invoice.currency

            invoice.line_items.forEach((el) => {
              this.items.unshift({
                article_id: el.article_id,
                article_no: el.article_sku,
                article_name: el.article_name,
                price_purchase: el.price_purchase,
                price_sale: el.price_sales,
                description: "",
                price: el.price_sales,
                percent_discount: el.percent_discount,
                discount: el.discount,
                percent_tax: el.percent_tax,
                quantity: el.quantity,
                total: 0,
                warehouse_code: el.warehouse_code,
                warehouse_name: "",
                stock_onhand: "",
                stocks: [],
              });

              this.articles.push({
                article_id: el.article_id,
                article_name: el.article_name,
                price_purchase: el.price_purchase,
                price_sale: el.price_sale,
                sku: el.article_sku,
              });
            });
            this.customers.push(this.customerInfo);
            this.loadingFull = false;
            // this.invoice = Object.assign({}, res.data);
          }, 1.5 * 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getAccounts() {
      await fetchAccounts()
        .then((res) => {
          this.accounts = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSelectAccount(item) {
      const accountExistList = this.payments.filter(
        (el) => el.account_number === item.account_number
      ).length;

      if (accountExistList > 1) {
        item.account_number = "";
        this.$message({
          message: "Veuillez selectionner un autre compte",
          type: "warning",
        });
      } else {
        const account = this.accounts.find(
          (el) => el.numero === item.account_number
        );
        item.account_name = account.account_name;
        item.amount = this.totalTTC;
      }
    },
    handleDeletePaymentItem(index) {
      this.payments.splice(index, 1);
    },
    handleAccount() {
      this.payments.push({
        account_number: "",
        account_name: "",
        amount: 0,
      });
    },
    handleConfirmeDialog(invoiceForm) {
      this.$refs[invoiceForm].validate((valid) => {
        if (valid) {
          this.confirmDialog = true;
        }
      });
    },

    handleEdit(row) {
      row.edit = !row.edit;
    },

    async fetchDueDays() {
      await fetchDueDays().then((res) => {
        this.duedays = res.data;
      });
    },

    handleDueDay() {
      const date = new Date();
      const terms = this.duedays.find((el) => el.id === this.invoice.terms);
      date.setTime(date.getTime() + 3600 * 1000 * 24 * terms.days);
      this.invoice.due_date = formatDate(date);
    },

    handleDelete(index) {
      this.invoice.line_items.splice(index, 1);
    },

    createData() {
      this.$refs["invoiceForm"].validate((valid) => {
        this.confirmDialog = false;
        if (valid) {
          this.loadingFull = true;
          this.invoice.line_items = this.items.filter(
            (el) => el.article_id !== "" && el.article_id !== undefined
          );
          this.invoice.payments = this.payments.filter(
            (el) =>
              el.account_number !== "" &&
              el.account_number !== null &&
              el.account_number !== undefined &&
              el.amount > 0
          );
          const id = this.$route.params && this.$route.params.id;
          updateDocument(this.invoice, this.typeDoc, id)
            .then(() => {
              setTimeout(() => {
                this.loadingFull = true;
                this.$router.push(`/sales/index?tab=CREDIT_NOTE`);
              }, 1.5 * 1000);
            })
            .catch((err) => {
              this.loadingFull = true;
              if (err.data.status === 400) {
                this.$message({
                  message:
                    "Veuillez renseigner les informations obligatoire et les articles",
                  type: "warning",
                });
              } else if (err.data.status === 409 || err.data.status === 404) {
                this.$message({
                  message: err.data.message,
                  type: "danger",
                });
              } else {
                this.$message({
                  message:
                    "Erreur serveur ! Merci de contacter l'administrateur",
                  type: "danger",
                });
              }
            });
        }
      });
    },
    handleCleanItem() {
      this.invoice.line_items = [];
    },
    handleSelectedCustomer(customer) {
      this.invoice.contact_id = customer.contact_id;
      const accountContactIndex = this.accounts.findIndex(
        (el) => el.type_account === "CONTACT_CREDIT"
      );
      if (accountContactIndex > -1) {
        this.accounts.splice(accountContactIndex, 1);
      }
      if (
        customer.account_credit_number != null &&
        customer.account_credit_balance > 0
      ) {
        this.accounts.unshift({
          numero: customer.account_credit_number,
          account_name: customer.account_credit_title,
          type_account: "CONTACT_CREDIT",
          amount: 0,
        });
      }
    },
    handleSearchArticle(data) {
      this.articles = data;
    },
    handeAddArticle() {
      this.items.push({
        article_id: "",
        article_no: "",
        article_name: "",
        price_purchase: 0,
        price_sale: 0,
        description: "",
        price: "",
        percent_tax: 0,
        percent_discount: false,
        discount: 0,
        quantity: 0,
        warehouse_code: "",
        warehouse_name: "",
        stock_onhand: "",
        stocks: [],
      });
    },

    handleSearchCustomer(data) {
      this.customers = data;
    },
    handleDeleteItem(id) {
      const index = this.items.findIndex((el) => el.article_id === id);
      this.items.splice(index, 1);
    },
    updateDiscount(e) {
      if (this.invoice.percent_discount) {
        if (e > 100) {
          this.invoice.discount = 0;
        } else {
          this.invoice.discount = e;
        }
      } else {
        this.invoice.discount = e;
      }
    },
    updatePercentDiscount(e) {
      this.invoice.percent_discount = e;
      this.invoice.discount = 0;
    },
    updateTaxe(e) {
      this.invoice.percent_tax = e;
    },
    updateShipping(e) {
      this.invoice.shipping_cost = e;
    },
    handleCreate(e) {
      this.invoice.status = e;
      this.createData();
    },
  },
};
</script>

<style></style>
